import { Component, OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../sevices/auth.service';

@Component({
  selector: 'app-find-invoice-by-filter',
  templateUrl: './find-invoice-by-filter.component.html',
  styleUrls: ['./find-invoice-by-filter.component.scss']
})
export class FindInvoiceByFilterComponent implements OnInit {
  searchValue: any = "";
  selected: any = "";
  searchParams: any;
  Data: any[];
  paginator!: MatPaginator;
  dataSource!: MatTableDataSource<any>;
  displayedColumns2: string[] = [
    "invoiceNumber",
    "invoiceDate",
    "sellerGSTIN",
    "buyerGSTIN",
    "einvoiceIRN",
    "einvoiceACKNumber",
    "einvoiceACKDate",
    "Discount",
    "OthChrg",
    "TotInvVal",
    "AssVal",
    "IgstVal",
    "CgstVal",
    "SgstVal",
    "Action"

    
  ];
  isProcessCompleted: boolean;
  constructor( public router: Router,
    private toastr: ToastrService,
    private service: AuthService,
    private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
  }
  Clear() {
    this.selected = "";
    this.searchValue = "";
    this.dataSource = new MatTableDataSource();

  }
  serviceChangeSearch(event){
    this.searchParams=event.value;
   }
   getFilterData(){
     console.log(this.searchValue,this.searchParams);
     this.ngxService.start();
     this.service.getFilterWiseData(this.searchValue,this.searchParams).subscribe((res:any)=>{
      this.ngxService.stop();
      if(res.status_code==1){
        this.isProcessCompleted=true;
        this.dataSource = new MatTableDataSource(res.object);
        this.dataSource.paginator = this.paginator;
      
        
      }else{
        this.toastr.error(res.error.message)
        this.ngxService.stop();
        }
    },err=>{
      this.ngxService.stop();
  
    })
   }
   downloadPdf(){

  }
}
