import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../sevices/auth.service';
import { MD5UtilService } from '../sevices/md5-util.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  AddGSTForm: FormGroup;
  gstRegex = "^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}Z[0-9a-zA-Z]{1}$";
  GSTInData: any;
  isAddGst: boolean = false;
  
  constructor( private formBuilder: FormBuilder,
    public router: Router,
    private toastr: ToastrService,
    private service: AuthService,
    private ngxService: NgxUiLoaderService,
    private md5Util:MD5UtilService) {

      this.AddGSTForm = this.formBuilder.group({
        gstNumber: ["", [Validators.required,Validators.minLength(15), Validators.pattern(this.gstRegex)]],
        legalName: ["", [Validators.required]],
        tradeName: ["", [Validators.required]],
        address1: ["", [Validators.required]],
        address2: ["", [Validators.required]],
        location: ["", [Validators.required]],
        pincode: ["", [Validators.required]],
        einvoiceUserName: ["", [Validators.required]],
        einvoicePassword: ["", [Validators.required]],
       
      });
    
     }

  ngOnInit() {
    this.getGSTIn();
  }
getGSTIn(){
  this.ngxService.start();
  this.service.getGSTIN().subscribe((res:any) =>{
    this.ngxService.stop();
    if(res.object){
      this.GSTInData=res.object;
      
    }else{
      this.isAddGst=true;
    }
  },err=>{
    this.ngxService.stop();

  })

}

addFlagTrue(){
  this.isAddGst=!this.isAddGst;

}
addGSTIN(){
  if (this.AddGSTForm.invalid) {
    return;
  }
  this.ngxService.start();
  this.service.addGSTIN(this.AddGSTForm.value).subscribe((res:any) =>{
    this.ngxService.stop();
    if(res.status_code==1){
    this.getGSTIn();
    this.toastr.success(res.message, "Success", {
      timeOut: 15000,
    });
    this.isAddGst=false;
    this.AddGSTForm.reset();
   
    this.AddGSTForm.get('gstNumber').clearValidators();
    this.AddGSTForm.get('gstNumber').updateValueAndValidity();

    this.AddGSTForm.get('legalName').clearValidators();
    this.AddGSTForm.get('legalName').updateValueAndValidity();

    this.AddGSTForm.get('tradeName').clearValidators();
    this.AddGSTForm.get('tradeName').updateValueAndValidity();

    this.AddGSTForm.get('address1').clearValidators();
    this.AddGSTForm.get('address1').updateValueAndValidity();

    this.AddGSTForm.get('address2').clearValidators();
    this.AddGSTForm.get('address2').updateValueAndValidity();
   
    this.AddGSTForm.get('einvoiceUserName').clearValidators();
    this.AddGSTForm.get('einvoiceUserName').updateValueAndValidity();
    
    this.AddGSTForm.get('einvoicePassword').clearValidators();
    this.AddGSTForm.get('einvoicePassword').updateValueAndValidity();
   
    }else{
      this.toastr.error(res.error.message, "Error", {
        timeOut: 15000,
      });
     }
  },err=>{
    this.ngxService.stop();

  })
}
numberOnly(event): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}
uploadInvoice(gst){
  this.router.navigate(['/upload-invoice',gst])

}

}
