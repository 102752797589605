import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ChartsModule } from "ng2-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material";
import { SharedModule } from "../app/shared/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatToolbarModule } from "@angular/material/toolbar";
import { ToastrModule } from "ngx-toastr";
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { MatDialogModule } from "@angular/material/dialog";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {MatMenuModule} from '@angular/material/menu';
import { MatSelectModule } from "@angular/material";
import { NgxPaginationModule } from "ngx-pagination";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ThakyouComponent } from './thakyou/thakyou.component';
import { DatePipe } from "@angular/common";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { HomeComponent } from './home/home.component';
import { UploadInvoiceComponent } from './upload-invoice/upload-invoice.component';
import { CommonComponent } from './common/common.component';
import { FindInvoiceByFilterComponent } from './find-invoice-by-filter/find-invoice-by-filter.component';
import { LotDetailsComponent } from './lot-details/lot-details.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 80,
  bgsType: "three-strings",
  fgsType: "three-strings",
  fgsSize: 100,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 6,
  text: "Loading.....",
  blur: 0,
  logoSize: 500,
};

@NgModule({
  declarations: [
    AppComponent,
    ThakyouComponent,
    HomeComponent,
    UploadInvoiceComponent,
    CommonComponent,
    FindInvoiceByFilterComponent,
    LotDetailsComponent,
    
    
  ],
  imports: [
    MatMenuModule,
    ChartsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatCardModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatToolbarModule,
    NgxPaginationModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      preventDuplicates: true,
      closeButton: true,
    }),
    NgxUiLoaderModule,
    MatDialogModule,
    NgbModule,
    SharedModule,
    MatSelectModule,
    InfiniteScrollModule
  ],
  exports: [
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatDialogModule,
  ],
  providers: [ DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
