import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MD5UtilService } from './md5-util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  ischeck: any;
  wallet_token: any;
  //login
  checkStatus = new BehaviorSubject<boolean>(false);
  isUserLoggedIn = this.checkStatus.asObservable();
  constructor(private http: HttpClient,
    private md5Util:MD5UtilService
    ) { }

  login(form){
    var pass=form.password
    var md5Password = this.md5Util.getMd5Checksum(pass);

    const data = {
      companyPan: form.companyPan.toUpperCase(),
      password: md5Password.toString()
   }
   return this.http.post(`${environment.URL_UAT}company/service/einvoice/login`, data);
  }

  checkLogin() {
    const auth_token = localStorage.getItem("auth-token");
      if (auth_token) {
      this.checkStatus.next(true);
    } else {
      this.checkStatus.next(false);
    }
  }
getUploadedInvoices(gstNumber,lotNumber){
  return this.http.get(`${environment.URL_UAT}company/einvoice/get/uploaded/invoices/${gstNumber}/${lotNumber}`);

}
getGSTIN(){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));

  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());

  return this.http.get(`${environment.URL_UAT}company/service/einvoice/fetch/gstin`, {headers: headers});

}
addGSTIN(form){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));

  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  var data={
    gstNumber:form.gstNumber.toUpperCase(),
    legalName: form.legalName,
    tradeName: form.tradeName,
    address1: form.address1,
    address2:form.address2,
    location: form.location,
    pincode: form.pincode,
    einvoiceUserName: form.einvoiceUserName,
    einvoicePassword: form.einvoicePassword
  }
  return this.http.post(`${environment.URL_UAT}company/service/einvoice/addgstin`, data,{headers: headers});
 
}

uploadExcelForInvoice(gstNumber,file){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));
  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  const formData = new FormData();
  formData.append("file", file);
  // var data={
  //   "file": file  }
  return this.http.post(`${environment.URL_UAT}company/service/einvoice/uploadexcel/${gstNumber}`,formData,{headers: headers});

}

getUploadedExcelForInvoice(gstNumber,lotNumber){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));
  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  console.log(headers);

  return this.http.get(`${environment.URL_UAT}company/service/einvoice/get/uploaded/invoices/${gstNumber}/${lotNumber}`,{headers: headers});

}


processInvoice(gstNumber,lotNumber){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));
  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  console.log(headers);

  return this.http.get(`${environment.URL_UAT}company/service/einvoice/process/einvoice?lotnumber=${lotNumber}&gstNumber=${gstNumber}`, {headers: headers});
}

getProcessInvoice(gstNumber,lotNumber){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));
  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  return this.http.get(`${environment.URL_UAT}company/service/einvoice/get/processed/einvoice?lotnumber=${lotNumber}&gstNumber=${gstNumber}`, {headers: headers});

}
getFilterWiseData(value,filter){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));
  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  return this.http.get(`${environment.URL_UAT}company/service/einvoice/get/processed/einvoice/filter?value=${value}&filters=${filter}`, {headers: headers});

}

getLotDetailsByStatus(){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));
  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  return this.http.get(`${environment.URL_UAT}company/service/einvoice/get/all/lots/?status=SUCCESS`, {headers: headers});
  
}
getCreditPoints(){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));
  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  return this.http.get(`${environment.URL_UAT}company/service/einvoice/get/points`, {headers: headers});
  
}
logout(){
  var auth_token=this.md5Util.Decrypt(localStorage.getItem('auth-token'));
  let headers = new HttpHeaders();
  headers = headers.append("auth_token", auth_token.toString());
  return this.http.get(`${environment.URL_UAT}company/service/einvoice/logout`, {headers: headers});
}
}
