import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../sevices/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  loggedIn = false;
  constructor(private route: Router, private auth: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.auth.checkLogin();
    this.auth.isUserLoggedIn.subscribe((val) => {
      if (val) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
    if (this.loggedIn) {
      this.route.navigate(["/home"]);
      return false;
    } else {
      return true;
    }
  }
}
