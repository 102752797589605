import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../sevices/auth.service';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit {
  creditPoints: any;

  constructor(public router: Router,
    private toastr: ToastrService,
    private service: AuthService,
    private ngxService: NgxUiLoaderService,
    ) { }

  ngOnInit() {
  this.getCreditPoints()

  }

  getCreditPoints(){
    this.service.getCreditPoints().subscribe((res:any)=>{
    if(res.status_code==1){
       this.creditPoints=res.object.remainingInvoiceCredit;
    }
    },err=>{
  
    })
   }
  
  logout(){
    this.ngxService.start();
    this.service.logout().subscribe((res:any)=>{
      this.ngxService.stop();
    if(res.status_code==1){
    this.router.navigate(['login']);
    localStorage.clear();
  
    }else{
      this.router.navigate(['login']);
      localStorage.clear();
  
    }
    },err=>{
      this.ngxService.stop();
  
    })
   }

}
