export const environment = {
  production: false,
  // apiuat
  //walletuat
  //yourMudra uat

  URL_UAT: "https://einvoicebalaji.mykumpany.com/",


  
};
