import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LogGuard } from "./guard/log-guard.guard";
import { AuthGuard } from "./guard/auth.guard";
import { HomeComponent } from "./home/home.component";
import { UploadInvoiceComponent } from "./upload-invoice/upload-invoice.component";
import { FindInvoiceByFilterComponent } from "./find-invoice-by-filter/find-invoice-by-filter.component";
import { LotDetailsComponent } from "./lot-details/lot-details.component";
const routes: Routes = [
  // {
  //   path:'thanks',
  //   component: ThakyouComponent
  // },
  {   path: "login", canActivate: [AuthGuard],loadChildren: () => import("./login/login.module").then((m) => m.LoginModule)},
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: 'home', component: HomeComponent },
  {path:'upload-invoice/:gst' , component:UploadInvoiceComponent },
  {path:'find-Einvoice' , component:FindInvoiceByFilterComponent },
  {path:'lot-details' , component:LotDetailsComponent }

]
 
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
