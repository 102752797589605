import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import {Location} from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../sevices/auth.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.scss']
})
export class UploadInvoiceComponent implements OnInit {
  gstNumber: any;
  deductUrl="https://images.yourmudra.com/uploadtoeinvoice.xlsx";
  file: any;
  lotNumber: any;

  displayedColumns: string[] = [
    "InvoiceNumber",
    "InvoiceDate",
    "BuyerGstin",
    "BuyerLegalName",
    "BuyerAddress1",
    "BuyerLocation",
    "BuyerPincode",
    "PrdDesc",
    "IsServc",
    "HsnCd",
    "Qty",
    "Unit",
    "UnitPrice",
    "TotAmt",
    "Discount",
    "AssAmt",
    "GstRt",
    "IgstAmt",
    "CgstAmt",
    "SgstAmt",
    "TotItemVal"

  ];

  displayedColumns2: string[] = [
    "invoiceNumber",
    "sellerGSTIN",
    "buyerGSTIN",
    "einvoiceIRN",
    "einvoiceACKNumber",
    "einvoiceACKDate",
    "Discount",
    "OthChrg",
    "TotInvVal",
    "AssVal",
    "IgstVal",
    "CgstVal",
    "SgstVal",
    "Action"

    
  ];
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  dataSource!: MatTableDataSource<any>;
  dataSource2!: MatTableDataSource<any>;
  isDataTable: boolean;
  isProcessCompleted: boolean;
 
  constructor(private activatedRoute: ActivatedRoute,private location:Location,
    public router: Router,
    private toastr: ToastrService,
    private service: AuthService,
    private ngxService: NgxUiLoaderService,
    ) {
    this.activatedRoute.params.subscribe(params => {
        this.gstNumber=params.gst
    });
   }

  ngOnInit() {

  }
  triggerFile(fileInput) {
    // do something
    fileInput.click();
  }

  fileChangeListener(event){
    console.log(event.target.files[0]);
      this.file = event.target.files[0];
      event.target.value = null;
    this.uploadFile();
    
  } 
  uploadFile() {
    this.ngxService.start();
    this.service.uploadExcelForInvoice(this.gstNumber,this.file)
      .subscribe(
        (res: any) => {
          console.log(res);
        this.file="";
          if(res.status_code==1) {
          this.lotNumber=res.object;
          this.isDataTable=true;
          this.toastr.success(res.message)
          this.executeLot(this.lotNumber);
          }
          else{
            this.toastr.error(res.error.message)
            this.ngxService.stop();
            }
            
        },
        (err) => {
          this.ngxService.stop();
        }
      );
  }

  executeLot(lotNumber) {
  
    this.service.getUploadedExcelForInvoice(this.gstNumber,lotNumber
      )
      .subscribe(
        (res: any) => {
          console.log(res);
          this.ngxService.stop(); 
          if(res.status_code==1){
            this.toastr.success(res.message);
            this.dataSource = new MatTableDataSource(res.object);
            this.dataSource.paginator = this.paginator;
            
            // this.deductLotList(this.itemsPerPage,0);
            }
            else
            this.toastr.error(res.error.message);
          
        },
        (err) => {
          this.ngxService.stop();
        }
      );
  }

back(){
this.location.back();
}

processSubmit(){
  this.ngxService.start();
  this.service.processInvoice(this.gstNumber,this.lotNumber)
    .subscribe(
      (res: any) => {
        console.log(res);
        this.ngxService.stop();

      this.file="";
        if(res.status_code==1) {
          this.toastr.success(res.message)
        this.getProcessDetail();
        }
        else{
          this.toastr.error(res.error.message)
          this.ngxService.stop();

          }
          
      },
      (err) => {
        this.ngxService.stop();
      }
    ); 
}
getProcessDetail(){
  this.ngxService.start();
  this.service.getProcessInvoice(this.gstNumber,this.lotNumber)
    .subscribe(
      (res: any) => {
        console.log(res);
        this.ngxService.stop();

         this.file="";
        if(res.status_code==1) {
          this.isProcessCompleted=true;
          this.isDataTable=false;
          this.dataSource2 = new MatTableDataSource(res.object);
          this.dataSource2.paginator = this.paginator;
         
        }
        else{
          this.toastr.error(res.error.message)
          this.ngxService.stop();
          }
          
      },
      (err) => {
        this.ngxService.stop();
      }
    ); 
}

downloadPdf(){

}
ExportData(){

}
}
