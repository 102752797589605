import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thakyou',
  templateUrl: './thakyou.component.html',
  styleUrls: ['./thakyou.component.scss']
})
export class ThakyouComponent implements OnInit {
  currentyear: number;

  constructor() { 
    localStorage.clear();
  }

  ngOnInit() {
    var d = new Date();
    this.currentyear = d.getFullYear();
    console.log(this.currentyear);
  }

}
