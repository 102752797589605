import { Component, OnInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../sevices/auth.service';

@Component({
  selector: 'app-lot-details',
  templateUrl: './lot-details.component.html',
  styleUrls: ['./lot-details.component.scss']
})
export class LotDetailsComponent implements OnInit {
  lotData: any;
  isProcessCompleted: boolean=false;
  paginator!: MatPaginator;
  dataSource!: MatTableDataSource<any>;
  displayedColumns2: string[] = [
    "invoiceNumber",
    "invoiceDate",
    "sellerGSTIN",
    "buyerGSTIN",
    "einvoiceIRN",
    "einvoiceACKNumber",
    "einvoiceACKDate",
    "Discount",
    "OthChrg",
    "TotInvVal",
    "AssVal",
    "IgstVal",
    "CgstVal",
    "SgstVal",
    "Action"

    
  ];
  constructor( public router: Router,
    private toastr: ToastrService,
    private service: AuthService,
    private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
    this.getLotDetailsByStatus();
  }
  getLotDetailsByStatus(){
    this.ngxService.start();
    this.service.getLotDetailsByStatus().subscribe((res:any) =>{
      this.ngxService.stop();
      if(res.object){
        this.lotData=res.object;
        
      }else{
        // this.isAddGst=true;
      }
    },err=>{
      this.ngxService.stop();
  
    })
  
  }
  openInvoiceDetailsByLotFilter(lotNumber){
    console.log(lotNumber);
    this.getFilterData(lotNumber);
  }
  getFilterData(lotNumber){
    var searchParams = "LOTNUMBER"
    console.log(lotNumber,searchParams);
    this.ngxService.start();
    this.service.getFilterWiseData(lotNumber,searchParams).subscribe((res:any)=>{
     this.ngxService.stop();
     if(res.status_code==1){
       this.isProcessCompleted=true;
       this.dataSource = new MatTableDataSource(res.object);
       this.dataSource.paginator = this.paginator;
     
       
     }else{
       this.toastr.error(res.error.message)
       this.ngxService.stop();
       }
   },err=>{
     this.ngxService.stop();
 
   })
  }
  downloadPdf(){

  }
}
