import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class MD5UtilService {

  constructor() { }
  getMd5Checksum(string) {
    return CryptoJS.MD5(string);
 }
 getAESEncrption(string){
  return CryptoJS.AES.encrypt (string,'wallet-token').toString();
}
Decrypt(string){

  return CryptoJS.AES.decrypt (string,'wallet-token').toString(CryptoJS.enc.Utf8);


}
}
